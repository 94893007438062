import React, { useState } from "react";
import './Form.css'

export default function Form() {
    const [result, setResult] = useState("");
    const [formData, setFormData] = useState({
        name: "",
        textarea: ""
    });
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let newErrors = {};
        if (!formData.name.trim()) newErrors.name = "Name is required";
        if (!formData.textarea.trim()) newErrors.textarea = "Feedback is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            setResult("Sending....");
            const formDataToSend = new FormData(event.target);

            formDataToSend.append("access_key", "12ce25bb-36db-40b8-aaeb-533627586bab");

            try {
                const response = await fetch("https://api.web3forms.com/submit", {
                    method: "POST",
                    body: formDataToSend
                });

                const data = await response.json();

                if (data.success) {
                    setResult("നന്ദി ഉണ്ടേയ്...  🙏😁");
                    setFormData({ name: "", textarea: "" });
                    setErrors({});
                } else {
                    console.log("Error", data);
                    setResult(data.message);
                }
            } catch (error) {
                console.error("Error submitting form:", error);
                setResult("An error occurred. Please try again.");
            }
        }
    };

    return (
        <div>
            <div className="form-container">
                <form className="form" onSubmit={onSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name <span>*</span></label>
                        <input
                            required=""
                            name="name"
                            id="name"
                            type="text"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        {errors.name && <small className="error-message">{errors.name}</small>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="textarea">അഭിപ്രായങ്ങള്  എഴുതിക്കോളീ... <span>*</span></label>
                        <textarea
                            required=""
                            cols="50"
                            rows="10"
                            id="textarea"
                            name="textarea"
                            value={formData.textarea}
                            onChange={handleChange}
                        ></textarea>
                        {errors.textarea && <small className="error-message">{errors.textarea}</small>}
                    </div>
                    <button type="submit" className="form-submit-btn">Submit</button>
                    <span className="status">{result}</span>
                </form>
            </div>
        </div>
    );
}