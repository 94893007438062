import React from 'react'
import './SliderCard.css'
import { Link } from 'react-router-dom';


function SliderCard({img,bg,title,id}) {
    return (
        <Link to={`/story/${id}/${title}`} className="slidercard">
            <div className="slidercardimage">
                <img src={img} alt="" />
            </div>
            <div className={bg}>
                <p className="slidercardtitle">{title}</p>
                {/* <p className="slidertitledesc">{description} </p>
                <div className="sliderhashdates">
                    <p className="sliderhsd1">{hash}</p>
                    <p className="sliderhsd2">1 month ago</p>
                </div> */}
            </div>
        </Link>
    )
}

export default SliderCard