import Home from "./pages/Home";
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import EpisodePlayer from "./pages/EpisodePlayer/EpisodePlayer";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/story/:id/:title" element={<EpisodePlayer />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
