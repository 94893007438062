import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './EpisodePlayer.css'
import backward from './backward.png'
import forward from './forward.png'
import tenf from './tenf.png'
import tenb from './tenb.png'
import play from './play.png'
import pauser from './pauser.png'
import lips from './lips.gif'
import share from './share.png'
import { Helmet } from 'react-helmet';

function EpisodePlayer() {
  const { id } = useParams();
  const { title } = useParams();
  const [story, setStory] = useState(null);
  const [currentEpisodeIndex, setCurrentEpisodeIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const audioRef = useRef(null);
  const progressBarRef = useRef(null);

  useEffect(() => {
    const fetchStory = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://storiesapi.onrender.com/api/audiostories/${id}/?format=json`);
        setStory(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching story:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchStory();
  }, [id]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      setCurrentTime(0);
      if (isPlaying) {
        audioRef.current.play().catch(e => console.log("Auto-play prevented:", e));
      }
    }
  }, [currentEpisodeIndex]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleEnded = () => {
        if (currentEpisodeIndex < story.episodes.length - 1) {
          setCurrentEpisodeIndex(prevIndex => prevIndex + 1);
        } else {
          setIsPlaying(false);
        }
      };

      audio.addEventListener('ended', handleEnded);

      return () => {
        audio.removeEventListener('ended', handleEnded);
      };
    }
  }, [currentEpisodeIndex, story]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play().catch(e => console.log("Play prevented:", e));
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  const handleNext = () => {
    if (currentEpisodeIndex < story.episodes.length - 1) {
      setCurrentEpisodeIndex(currentEpisodeIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentEpisodeIndex > 0) {
      setCurrentEpisodeIndex(currentEpisodeIndex - 1);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleFastForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 10;
    }
  };

  const handleRewind = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 10;
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleSeek = (event) => {
    const progressBar = progressBarRef.current;
    const audio = audioRef.current;
    if (progressBar && audio) {
      const rect = progressBar.getBoundingClientRect();
      const clickPosition = (event.clientX || event.touches[0].clientX) - rect.left;
      const newTime = (clickPosition / rect.width) * audio.duration;
      audio.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleSeekStart = () => {
    setIsSeeking(true);
    setIsPlaying(false);
    audioRef.current.pause();
  };

  const handleSeekEnd = () => {
    setIsSeeking(false);
    if (isPlaying) {
      audioRef.current.play();
    }
  };

  const handleShare = async () => {
    if (!story || !currentEpisode) return;

    const shareData = {
      title: `${story.title} - Episode ${currentEpisode.episode_number}`,
      text: `Check out this story of ${story.title}!`,
      url: window.location.href,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
        console.log('Shared successfully');
      } catch (err) {
        console.log('Error: ' + err);
      }
    } else {
      alert('Web Share not supported on this browser. You can copy this link: ' + window.location.href);
    }
  };

  if (loading) return <div className='loading'> <img src={lips} alt="loader" /></div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!story) return <div>Story not found</div>;

  const currentEpisode = story.episodes[currentEpisodeIndex];

  return (
    <div className='playercontainer'>
      <Helmet>
        <title>{`${title} - Episode ${currentEpisode.episode_number} | Kambikathakal`}</title>
        <meta name="description" content={`Listen to ${title} - Episode ${currentEpisode.episode_number}, a Malayalam audio story on Kambikathakal.fun`} />
        <meta property="og:title" content={`${title} - Episode ${currentEpisode.episode_number} | Kambikathakal`} />
        <meta property="og:description" content={`Listen to ${title} - Episode ${currentEpisode.episode_number}, a Malayalam audio story on Kambikathakal.fun`} />
        <meta property="og:image" content={currentEpisode.image_url} />
        <meta property="og:url" content={`https://kambikathakal.fun/story/${id}/${title}`} />
        <link rel="canonical" href={`https://kambikathakal.fun/story/${id}/${title}`} />
      </Helmet>
      <div className="episodetitle">
        <div className="text-container">
          <p>{title}</p>
        </div>
      </div>
      <div className="episodeimage">
        <img src={currentEpisode.image_url} alt={currentEpisode.title} />
      </div>
      <div className="episodedetails">
        <p className="title">{currentEpisode.title}</p>
        <div className="epishare">
          <p className="number">Episode {currentEpisode.episode_number}</p>
          <button className="control-button share" onClick={handleShare}> <img src={share} alt="share" /></button>
        </div>
      </div>
      <div className="audio-controls">
        <div 
          className="progress-bar" 
          ref={progressBarRef}
          onMouseDown={handleSeekStart}
          onTouchStart={handleSeekStart}
          onMouseMove={isSeeking ? handleSeek : null}
          onTouchMove={isSeeking ? handleSeek : null}
          onMouseUp={handleSeekEnd}
          onTouchEnd={handleSeekEnd}
          onMouseLeave={handleSeekEnd}
        >
          <div className="progress" style={{ width: `${(currentTime / duration) * 100 || 0}%` }}>
            <div className="progress-circle"></div>
          </div>
        </div>
        <div className="time-display">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>
        <div className="controls">
          <button className="control-button prev-next" onClick={handlePrev} disabled={currentEpisodeIndex === 0}> <img src={backward} alt="previous" /></button>
          <button className="control-button" onClick={handleRewind}>
            <img src={tenb} alt="backward" />
          </button>
          <button className="control-button play-pause" onClick={togglePlayPause}>
            {isPlaying ? <img src={pauser} alt="pause" /> : <img src={play} alt="play" />}
          </button>
          <button className="control-button" onClick={handleFastForward}> <img src={tenf} alt="foward" /></button>
          <button className="control-button prev-next" onClick={handleNext} disabled={currentEpisodeIndex === story.episodes.length - 1}> <img src={forward} alt="next" /></button>
        </div>
      </div>
      <audio 
        ref={audioRef} 
        src={currentEpisode.audio_file_url}
        onTimeUpdate={() => setCurrentTime(audioRef.current.currentTime)}
        onDurationChange={() => setDuration(audioRef.current.duration)}
      />
    </div>
  );
}

export default EpisodePlayer;