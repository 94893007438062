import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import './Home.css'
//import AudioBar from '../components/AudioBar/AudioBar';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AudioProvider } from '../AudioContext';
import StoryCard from '../components/StoryCard/StoryCard';
import SliderCard from '../components/SliderCards/SliderCard';
import { Helmet } from 'react-helmet';
import Form from '../components/Feedback/Form';


function Home() {
    const [latestStories, setLatestStories] = useState([]);
    const [trendingStories, setTrendingStories] = useState([]);
    const [allStories, setAllStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const allStoriesRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [latestResponse, trendingResponse, allResponse] = await Promise.all([
                    axios.get('https://storiesapi.onrender.com/api/audiostories/latest_four/'),
                    axios.get('https://storiesapi.onrender.com/api/audiostories/trending/'),
                    axios.get('https://storiesapi.onrender.com/api/audiostories/')
                ]);

                setLatestStories(latestResponse.data);
                setTrendingStories(trendingResponse.data);
                setAllStories(allResponse.data);
            } catch (error) {
                console.error('Error fetching audio data:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
    };

    const scrollToAllStories = () => {
        allStoriesRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <AudioProvider>
            <Helmet>
                <title>Kambikathakal - മലയാളം കമ്പികതകൾ | Audio Stories</title>
                <meta name="description" content="Explore the latest and trending Malayalam audio stories at Kambikathakal.fun. Unique collection of കമ്പികഥകൾ." />
                <meta name="keywords" content="കമ്പികഥകൾ, Malayalam stories, audio stories, kambikathakal, മലയാളം കഥകൾ , malayalam kambi stories, kambi,malayalam kambi audio,kambi audio,kambikuttan" />
                <meta name="author" content="Kambikathakal" />
                <meta name="robots" content="index, follow" />
                <meta name="language" content="Malayalam" />
                <link rel="canonical" href="https://kambikathakal.fun" />
            </Helmet>
            <div>
                <div className="main-title-bg">
                    <h1>kambikathakal.fun</h1>
                    <p>മലയാളം കമ്പികതകളുടെ അപൂർവ ശേഗരം</p>
                </div>
                <h3>Latest Stories <span className="moods">👙🫦</span></h3>
                <div className="homecards-cont">
                    {loading ? (
                        <img src="images/lips.gif" alt="Loading" />
                    ) : error ? (
                        <p>Error: {error.message}</p>
                    ) : latestStories.length > 0 ? (
                        latestStories.map((story) => <StoryCard key={story.id} story={story} />)
                    ) : (
                        <p>No stories available.</p>
                    )}
                </div>
                <h2 className='pwoli'>അപ്പൊ മുത്തുമണീസേ <span className="earphones">Earphones 🎧</span> കുത്തിക്കോളീ... പൊളിച്ചോളീ....😘 </h2>
                <h3 className='show-in-mini'>Trending Collections <span className="moods">😘💋</span></h3>
                <Slider {...settings}>
                    {loading ? (
                        <img src="images/lips.gif" alt="Loading" />
                    ) : error ? (
                        <p>Error: {error.message}</p>
                    ) : trendingStories.length > 0 ? (
                        trendingStories.map((story, index) => (
                            <SliderCard
                                key={story.id}
                                img={story.image_url}
                                bg={index % 2 === 0
                                    ? 'slidercarddescription slider-green-bg'
                                    : 'slidercarddescription slider-blue-bg'
                                }
                                title={story.title}
                                id={story.id}
                            />
                        ))
                    ) : (
                        <p>No trending stories available.</p>
                    )}
                </Slider>
                <div className="tapot">
                    <button className="button" onClick={scrollToAllStories}>താപ്പോട്ട്  പൊയ്‌ക്കോളീ..👇</button>
                </div>
                <section id="about-us">
                    <h2>About Kambikathakal.fun</h2>
                    <p>Welcome to Kambikathakal.fun, your premier destination for Malayalam sex audio stories that explore the nuances of romantic relationships and adult themes. Our platform is dedicated to providing a unique and immersive listening experience for mature audiences.</p>

                    <h3>Our Mission</h3>
                    <p>We strive to offer a diverse collection of high-quality, engaging audio narratives that cater to the interests of our adult listeners. Our kambi stories blend creativity, passion, and cultural sensitivity to deliver content that resonates with our mallu audience.</p>

                    <h3>What We Offer</h3>
                    <ul>
                        <li>Carefully curated selection of romantic and mature-themed audio stories</li>
                        <li>Original content created by talented writers and voice artists</li>
                        <li>Easy-to-use platform for an enjoyable listening experience</li>
                        <li>Regular updates with new and exciting audio stories</li>
                    </ul>

                    <h3>Our Commitment</h3>
                    <p>At Kambikathakal.fun, we are committed to:
                        <ul>
                            <li>Maintaining the privacy and discretion of our listeners</li>
                            <li>Providing a safe and secure platform for accessing our audio kambi stories</li>
                            <li>Continuously improving our audio collections to meet the evolving interests of our audience</li>
                            <li>Fostering a respectful community of adult listeners and creators</li>
                        </ul>
                    </p>

                    <p>We invite you to explore our world of kambi audio stories, where imagination and passion intertwine. Remember to use headphones for the best and most private listening experience.</p>

                    <p>Kambikathakal.fun - Where words whisper and kambikathakal come alive.</p>
                </section>
                <h3 style={{ color: '#14b614' }} ref={allStoriesRef}>All Stories <span className="moods">🔞</span></h3>
                <div className="homecards-cont">
                    {loading ? (
                        <img src="images/lips.gif" alt="Loading" />
                    ) : error ? (
                        <p>Error: {error.message}</p>
                    ) : allStories.length > 0 ? (
                        allStories.map((story) => <StoryCard key={story.id} story={story} />)
                    ) : (
                        <p>No stories available.</p>
                    )}
                </div>
                <div className="feedbackform">
                    <h2 className="pwoli-feed">ഞമ്മക്ക്  എങ്ങനെ ഈ വെബ്സൈറ്റ് പൊളപ്പൻ ആക്കാം...🤔? <br /> താഴെ  പറഞ്ഞോളീ...👇 </h2>
                    <Form />
                </div>
                <footer>
                    <p><span>Made with 🩵 by kambikuttans</span></p>
                    <p><a href="mailto:kambikuttansofficial@gmail.com">kambikuttansofficial@gmail.com</a></p>
                </footer>
            </div>
        </AudioProvider>
    );
}

export default Home