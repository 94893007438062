import React from 'react';
import { Link } from 'react-router-dom';
import './StoryCard.css'
//import thumb from './thumb.jpg'

const StoryCard = ({ story }) => {
  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 30) {
      return `${diffDays} days ago`;
    } else if (diffDays < 365) {
      const months = Math.floor(diffDays / 30);
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
      const years = Math.floor(diffDays / 365);
      return `${years} year${years > 1 ? 's' : ''} ago`;
    }
  };

  const randomColors = ['rgb(183 0 0 / 87%)', '#088008de', '#0719a5de', '#73789ede'];
  const randomColor = randomColors[Math.floor(Math.random() * randomColors.length)];


  return (
    <Link to={`/story/${story.id}/${story.title}`} className="storycard">

      <div className="cardimage">
        {/* <img src={thumb} alt={story.title} /> */}
        <img src={story.image_url} alt={story.title} />
      </div>
      <div className="carddescription" style={{
        background: `linear-gradient(180deg, ${randomColor}, #000000)`
      }}>
        <p className="cardtitle">{story.title}</p>
        <p className="titledesc">{story.description}</p>
        <div className="hashdates">
          <p className="hsd1">
            {story.hashtags.split(' ').map((hashtag, index) => (
              <span key={index} className={index % 2 === 0 ? 'even-hashtag' : 'odd-hashtag'}>
                {hashtag}{' '}
              </span>
            ))}
          </p>
          <p className="hsd2">{formatDate(story.uploaded_at)}</p>
        </div>
      </div>

    </Link>
  );
};

export default StoryCard;